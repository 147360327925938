





import { Component, Vue } from 'vue-property-decorator';
import agreement from '@/views/agreement/index.vue';
@Component({
    name: 'TestIndex',
    components: {
        agreement
    }
})
export default class TestIndex extends Vue {
    private mounted() {
        // aa
    }
}
